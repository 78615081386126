const AUDIENCE_MAP = {
    interest1: "interest - academic interests",
    interest2: "interest - arts and humanities",
    interest3: "interest - critical thinking",
    interest4: "interest - counseling",
    interest5: "interest - history",
    interest6: "interest - music and art",
    interest7: "interest - philosophy",
    interest8: "interest - language learning",
    interest9: "interest - life sciences",
    interest10: "interest - animals and veterinary science",
    interest11: "interest - bioinformatics",
    interest12: "interest - biology",
    interest13: "interest - medicine and healthcare",
    interest14: "interest - nutrition",
    interest15: "interest - clinical science",
    interest16: "interest - genetics",
    interest17: "interest - physical science and engineering",
    interest18: "interest - electrical engineering",
    interest19: "interest - mechanical engineering",
    interest20: "interest - chemistry",
    interest21: "interest - environmental science and sustainability",
    interest22: "interest - research methods",
    interest23: "interest - geography",
    interest24: "interest - geology",
    interest25: "interest - physics",
    interest26: "interest - space and astronomy",
    interest27: "interest - social sciences",
    interest28: "interest - psychology",
    interest29: "interest - international relations",
    interest30: "interest - research paper writing",
    interest31: "interest - statistics",
    interest32: "interest - law",
    interest33: "interest - critical thinking",
    interest34: "interest - economics",
    interest35: "interest - education",
    interest36: "interest - governance and society",
    interest37: "interest - automotive",
    interest38: "interest - auto buying and selling",
    interest39: "interest - auto shows",
    interest40: "interest - auto technology",
    interest41: "interest - budget cars",
    interest42: "interest - car culture",
    interest43: "interest - classic cars",
    interest44: "interest - concept cars",
    interest45: "interest - dash cam videos",
    interest46: "interest - driverless cars",
    interest47: "interest - green vehicles",
    interest48: "interest - luxury cars",
    interest49: "interest - motorcycles",
    interest50: "interest - performance cars",
    interest51: "interest - scooters",
    interest52: "interest - books and literature",
    interest53: "interest - art and photography books",
    interest54: "interest - biographies",
    interest55: "interest - children's literature",
    interest56: "interest - comics and graphic novels",
    interest57: "interest - cookbooks",
    interest58: "interest - fiction",
    interest59: "interest - poetry",
    interest60: "interest - travel books",
    interest61: "interest - young adult literature",
    interest62: "interest - business and finance",
    interest63: "interest - business",
    interest64: "interest - business accounting & finance",
    interest65: "interest - human resources",
    interest66: "interest - large business",
    interest67: "interest - logistics",
    interest68: "interest - marketing and advertising",
    interest69: "interest - sales",
    interest70: "interest - small and medium-sized business",
    interest71: "interest - startups",
    interest72: "interest - business administration",
    interest73: "interest - business banking & finance",
    interest74: "interest - angel investment",
    interest75: "interest - bankruptcy",
    interest76: "interest - business loans",
    interest77: "interest - debt factoring & invoice discounting",
    interest78: "interest - mergers and acquisitions",
    interest79: "interest - private equity",
    interest80: "interest - sale & lease back",
    interest81: "interest - venture capital",
    interest82: "interest - business i.t.",
    interest83: "interest - business operations",
    interest84: "interest - consumer issues",
    interest85: "interest - executive leadership & management",
    interest86: "interest - government business",
    interest87: "interest - green solutions",
    interest88: "interest - business utilities",
    interest89: "interest - economy",
    interest90: "interest - commodities",
    interest91: "interest - currencies",
    interest92: "interest - financial crisis",
    interest93: "interest - financial reform",
    interest94: "interest - financial regulation",
    interest95: "interest - gasoline prices",
    interest96: "interest - housing market",
    interest97: "interest - interest rates",
    interest98: "interest - job market",
    interest99: "interest - industries",
    interest100: "interest - advertising industry",
    interest101: "interest - education industry",
    interest102: "interest - entertainment industry",
    interest103: "interest - environmental services industry",
    interest104: "interest - financial industry",
    interest105: "interest - food industry",
    interest106: "interest - healthcare industry",
    interest107: "interest - hospitality industry",
    interest108: "interest - information services industry",
    interest109: "interest - legal services industry",
    interest110: "interest - logistics and transportation industry",
    interest111: "interest - agriculture",
    interest112: "interest - management consulting industry",
    interest113: "interest - manufacturing industry",
    interest114: "interest - mechanical and industrial engineering industry",
    interest115: "interest - media industry",
    interest116: "interest - metals industry",
    interest117: "interest - non-profit organizations",
    interest118: "interest - pharmaceutical industry",
    interest119: "interest - power and energy industry",
    interest120: "interest - publishing industry",
    interest121: "interest - real estate industry",
    interest122: "interest - apparel industry",
    interest123: "interest - retail industry",
    interest124: "interest - technology industry",
    interest125: "interest - telecommunications industry",
    interest126: "interest - automotive industry",
    interest127: "interest - aviation industry",
    interest128: "interest - biotech and biomedical industry",
    interest129: "interest - civil engineering industry",
    interest130: "interest - construction industry",
    interest131: "interest - defense industry",
    interest132: "interest - careers",
    interest133: "interest - apprenticeships",
    interest134: "interest - career advice",
    interest135: "interest - career planning",
    interest136: "interest - remote working",
    interest137: "interest - vocational training",
    interest138: "interest - education",
    interest139: "interest - adult education",
    interest140: "interest - language learning",
    interest141: "interest - online education",
    interest142: "interest - family and relationships",
    interest143: "interest - parenting",
    interest144: "interest - daycare and pre-school",
    interest145: "interest - internet safety",
    interest146: "interest - parenting babies and toddlers",
    interest147: "interest - parenting children aged 4-11",
    interest148: "interest - parenting teens",
    interest149: "interest - fine art",
    interest150: "interest - costume",
    interest151: "interest - dance",
    interest152: "interest - design",
    interest153: "interest - digital arts",
    interest154: "interest - fine art photography",
    interest155: "interest - modern art",
    interest156: "interest - opera",
    interest157: "interest - theater",
    interest158: "interest - food & drink",
    interest159: "interest - alcoholic beverages",
    interest160: "interest - barbecues and grilling",
    interest161: "interest - cooking",
    interest162: "interest - desserts and baking",
    interest163: "interest - dining out",
    interest164: "interest - food allergies",
    interest165: "interest - food movements",
    interest166: "interest - healthy cooking and eating",
    interest167: "interest - non-alcoholic beverages",
    interest168: "interest - vegan diets",
    interest169: "interest - vegetarian diets",
    interest170: "interest - world cuisines",
    interest171: "interest - health and medical services",
    interest172: "interest - health & pharma",
    interest173: "interest - medical services",
    interest174: "interest - health services",
    interest175: "interest - health care and physicians",
    interest176: "interest - alternative and natural medicine",
    interest177: "interest - cosmetic medical services",
    interest178: "interest - drugstores and pharmacies",
    interest179: "interest - elder care",
    interest180: "interest - vision care",
    interest181: "interest - dental care",
    interest182: "interest - massage therapists",
    interest183: "interest - physical therapists",
    interest184: "interest - chiropractors",
    interest185: "interest - hospitals",
    interest186: "interest - skin care treatments",
    interest187: "interest - smoking cessation",
    interest188: "interest - clinical research",
    interest189: "interest - hair loss treatments",
    interest190: "interest - vaccines",
    interest191: "interest - healthy living",
    interest192: "interest - children's health",
    interest193: "interest - fitness and exercise",
    interest194: "interest - participant sports",
    interest195: "interest - running and jogging",
    interest196: "interest - men's health",
    interest197: "interest - nutrition",
    interest198: "interest - senior health",
    interest199: "interest - weight loss",
    interest200: "interest - wellness",
    interest201: "interest - alternative medicine",
    interest202: "interest - herbs and supplements",
    interest203: "interest - holistic health",
    interest204: "interest - physical therapy",
    interest205: "interest - smoking cessation",
    interest206: "interest - women's health",
    interest207: "interest - hobbies & interests",
    interest208: "interest - antiquing and antiques",
    interest209: "interest - arts and crafts",
    interest210: "interest - beadwork",
    interest211: "interest - candle and soap making",
    interest212: "interest - drawing and sketching",
    interest213: "interest - jewelry making",
    interest214: "interest - needlework",
    interest215: "interest - painting",
    interest216: "interest - photography",
    interest217: "interest - scrapbooking",
    interest218: "interest - woodworking",
    interest219: "interest - beekeeping",
    interest220: "interest - birdwatching",
    interest221: "interest - cigars",
    interest222: "interest - collecting",
    interest223: "interest - comic books",
    interest224: "interest - stamps and coins",
    interest225: "interest - content production",
    interest226: "interest - audio production",
    interest227: "interest - freelance writing",
    interest228: "interest - screenwriting",
    interest229: "interest - video production",
    interest230: "interest - games and puzzles",
    interest231: "interest - board games and puzzles",
    interest232: "interest - card games",
    interest233: "interest - roleplaying games",
    interest234: "interest - genealogy and ancestry",
    interest235: "interest - magic and illusion",
    interest236: "interest - model toys",
    interest237: "interest - musical instruments",
    interest238: "interest - paranormal phenomena",
    interest239: "interest - radio control",
    interest240: "interest - sci-fi and fantasy",
    interest241: "interest - workshops and classes",
    interest242: "interest - home & garden",
    interest243: "interest - gardening",
    interest244: "interest - home entertaining",
    interest245: "interest - home improvement",
    interest246: "interest - interior decorating",
    interest247: "interest - landscaping",
    interest248: "interest - outdoor decorating",
    interest249: "interest - remodeling & construction",
    interest250: "interest - smart home",
    interest251: "interest - medical health",
    interest252: "interest - movies",
    interest253: "interest - action and adventure movies",
    interest254: "interest - animation movies",
    interest255: "interest - comedy movies",
    interest256: "interest - crime and mystery movies",
    interest257: "interest - documentary movies",
    interest258: "interest - drama movies",
    interest259: "interest - family and children movies",
    interest260: "interest - fantasy movies",
    interest261: "interest - horror movies",
    interest262: "interest - indie and arthouse movies",
    interest263: "interest - romance movies",
    interest264: "interest - science fiction movies",
    interest265: "interest - world movies",
    interest266: "interest - music and audio",
    interest267: "interest - adult album alternative",
    interest268: "interest - adult contemporary music",
    interest269: "interest - soft ac music",
    interest270: "interest - urban ac music",
    interest271: "interest - alternative music",
    interest272: "interest - blues",
    interest273: "interest - children's music",
    interest274: "interest - classic hits",
    interest275: "interest - classical music",
    interest276: "interest - college radio",
    interest277: "interest - comedy (music and audio)",
    interest278: "interest - contemporary hits/pop/top 40",
    interest279: "interest - country music",
    interest280: "interest - dance and electronic music",
    interest281: "interest - gospel music",
    interest282: "interest - hip hop music",
    interest283: "interest - inspirational/new age music",
    interest284: "interest - jazz",
    interest285: "interest - oldies/adult standards",
    interest286: "interest - r&b/soul/funk",
    interest287: "interest - reggae",
    interest288: "interest - religious (music and audio)",
    interest289: "interest - rock music",
    interest290: "interest - album-oriented rock",
    interest291: "interest - alternative rock",
    interest292: "interest - classic rock",
    interest293: "interest - hard rock",
    interest294: "interest - soft rock",
    interest295: "interest - songwriters/folk",
    interest296: "interest - soundtracks, tv and showtunes",
    interest297: "interest - sports radio",
    interest298: "interest - talk radio",
    interest299: "interest - business news radio",
    interest300: "interest - educational radio",
    interest301: "interest - news radio",
    interest302: "interest - news/talk radio",
    interest303: "interest - public radio",
    interest304: "interest - urban contemporary music",
    interest305: "interest - variety (music and audio)",
    interest306: "interest - world/international music",
    interest307: "interest - news and politics",
    interest308: "interest - crime",
    interest309: "interest - disasters",
    interest310: "interest - international news",
    interest311: "interest - law",
    interest312: "interest - local news",
    interest313: "interest - national news",
    interest314: "interest - politics",
    interest315: "interest - elections",
    interest316: "interest - political issues",
    interest317: "interest - war and conflicts",
    interest318: "interest - weather",
    interest319: "interest - personal finance",
    interest320: "interest - frugal living",
    interest321: "interest - insurance",
    interest322: "interest - personal debt",
    interest323: "interest - personal investing",
    interest324: "interest - personal taxes",
    interest325: "interest - retirement planning",
    interest326: "interest - pets",
    interest327: "interest - birds",
    interest328: "interest - cats",
    interest329: "interest - dogs",
    interest330: "interest - fish and aquariums",
    interest331: "interest - horses and equine",
    interest332: "interest - large animals",
    interest333: "interest - pet adoptions",
    interest334: "interest - reptiles",
    interest335: "interest - pharmaceuticals, conditions, and symptoms",
    interest336: "interest - health & pharma",
    interest337: "interest - pop culture",
    interest338: "interest - humor and satire",
    interest339: "interest - real estate",
    interest340: "interest - apartments",
    interest341: "interest - developmental sites",
    interest342: "interest - hotel properties",
    interest343: "interest - houses",
    interest344: "interest - industrial property",
    interest345: "interest - land and farms",
    interest346: "interest - office property",
    interest347: "interest - real estate buying and selling",
    interest348: "interest - real estate renting and leasing",
    interest349: "interest - retail property",
    interest350: "interest - vacation properties",
    interest351: "interest - shopping",
    interest352: "interest - sports",
    interest353: "interest - american football",
    interest354: "interest - australian rules football",
    interest355: "interest - auto racing",
    interest356: "interest - motorcycle sports",
    interest357: "interest - badminton",
    interest358: "interest - baseball",
    interest359: "interest - basketball",
    interest360: "interest - beach volleyball",
    interest361: "interest - bodybuilding",
    interest362: "interest - bowling",
    interest363: "interest - boxing",
    interest364: "interest - cheerleading",
    interest365: "interest - college sports",
    interest366: "interest - college football",
    interest367: "interest - college basketball",
    interest368: "interest - college baseball",
    interest369: "interest - cricket",
    interest370: "interest - cycling",
    interest371: "interest - darts",
    interest372: "interest - disabled sports",
    interest373: "interest - diving",
    interest374: "interest - equine sports",
    interest375: "interest - horse racing",
    interest376: "interest - extreme sports",
    interest377: "interest - canoeing and kayaking",
    interest378: "interest - climbing",
    interest379: "interest - paintball",
    interest380: "interest - scuba diving",
    interest381: "interest - skateboarding",
    interest382: "interest - snowboarding",
    interest383: "interest - surfing and bodyboarding",
    interest384: "interest - waterskiing and wakeboarding",
    interest385: "interest - fantasy sports",
    interest386: "interest - field hockey",
    interest387: "interest - figure skating",
    interest388: "interest - fishing sports",
    interest389: "interest - golf",
    interest390: "interest - gymnastics",
    interest391: "interest - hunting and shooting",
    interest392: "interest - ice hockey",
    interest393: "interest - inline skating",
    interest394: "interest - lacrosse",
    interest395: "interest - martial arts",
    interest396: "interest - olympic sports",
    interest397: "interest - summer olympic sports",
    interest398: "interest - winter olympic sports",
    interest399: "interest - poker and professional gambling",
    interest400: "interest - rodeo",
    interest401: "interest - rowing",
    interest402: "interest - rugby",
    interest403: "interest - rugby league",
    interest404: "interest - rugby union",
    interest405: "interest - sailing",
    interest406: "interest - skiing",
    interest407: "interest - snooker/pool/billiards",
    interest408: "interest - soccer",
    interest409: "interest - softball",
    interest410: "interest - sports equipment",
    interest411: "interest - squash",
    interest412: "interest - swimming",
    interest413: "interest - table tennis",
    interest414: "interest - tennis",
    interest415: "interest - track and field",
    interest416: "interest - volleyball",
    interest417: "interest - walking",
    interest418: "interest - water polo",
    interest419: "interest - weightlifting",
    interest420: "interest - wrestling",
    interest421: "interest - style & fashion",
    interest422: "interest - beauty",
    interest423: "interest - body art",
    interest424: "interest - children's clothing",
    interest425: "interest - designer clothing",
    interest426: "interest - fashion trends",
    interest427: "interest - high fashion",
    interest428: "interest - men's fashion",
    interest429: "interest - personal care",
    interest430: "interest - street style",
    interest431: "interest - women's fashion",
    interest432: "interest - technology & computing",
    interest433: "interest - artificial intelligence",
    interest434: "interest - augmented reality",
    interest435: "interest - computing",
    interest436: "interest - internet",
    interest437: "interest - cloud computing",
    interest438: "interest - web development",
    interest439: "interest - web hosting",
    interest440: "interest - email",
    interest441: "interest - internet for beginners",
    interest442: "interest - internet of things",
    interest443: "interest - it and internet support",
    interest444: "interest - search",
    interest445: "interest - social networking",
    interest446: "interest - web design and html",
    interest447: "interest - programming languages",
    interest448: "interest - consumer electronics",
    interest449: "interest - robotics",
    interest450: "interest - virtual reality",
    interest451: "interest - television",
    interest452: "interest - animation tv",
    interest453: "interest - children's tv",
    interest454: "interest - comedy tv",
    interest455: "interest - drama tv",
    interest456: "interest - factual tv",
    interest457: "interest - holiday tv",
    interest458: "interest - music tv",
    interest459: "interest - reality tv",
    interest460: "interest - science fiction tv",
    interest461: "interest - soap opera tv",
    interest462: "interest - special interest tv",
    interest463: "interest - sports tv",
    interest464: "interest - travel",
    interest465: "interest - adventure travel",
    interest466: "interest - africa travel",
    interest467: "interest - asia travel",
    interest468: "interest - australia and oceania travel",
    interest469: "interest - beach travel",
    interest470: "interest - camping",
    interest471: "interest - day trips",
    interest472: "interest - europe travel",
    interest473: "interest - family travel",
    interest474: "interest - north america travel",
    interest475: "interest - polar travel",
    interest476: "interest - road trips",
    interest477: "interest - south america travel",
    interest478: "interest - video gaming",
    interest479: "interest - console games",
    interest480: "interest - esports",
    interest481: "interest - mobile games",
    interest482: "interest - pc games",
    interest483: "interest - video game genres",
    interest484: "interest - action video games",
    interest485: "interest - role-playing video games",
    interest486: "interest - simulation video games",
    interest487: "interest - sports video games",
    interest488: "interest - strategy video games",
    interest489: "interest - action-adventure video games",
    interest490: "interest - adventure video games",
    interest491: "interest - casual games",
    interest492: "interest - educational video games",
    interest493: "interest - exercise and fitness video games",
    interest494: "interest - mmos",
    interest495: "interest - music and party video games",
    interest496: "interest - puzzle video games",
    "purchase intent1": "purchase intent - apps",
    "purchase intent2": "purchase intent - auto and vehicles apps",
    "purchase intent3": "purchase intent - books apps",
    "purchase intent4": "purchase intent - business apps",
    "purchase intent5": "purchase intent - education apps",
    "purchase intent6": "purchase intent - entertainment apps",
    "purchase intent7": "purchase intent - finance apps",
    "purchase intent8": "purchase intent - food and drink apps",
    "purchase intent9": "purchase intent - games apps",
    "purchase intent10": "purchase intent - health and fitness apps",
    "purchase intent11": "purchase intent - lifestyle apps",
    "purchase intent12": "purchase intent - magazine and newspapers apps",
    "purchase intent13": "purchase intent - medical apps",
    "purchase intent14": "purchase intent - music apps",
    "purchase intent15": "purchase intent - navigation apps",
    "purchase intent16": "purchase intent - news apps",
    "purchase intent17": "purchase intent - photo and video apps",
    "purchase intent18": "purchase intent - productivity apps",
    "purchase intent19": "purchase intent - reference apps",
    "purchase intent20": "purchase intent - search engine apps",
    "purchase intent21": "purchase intent - shopping apps",
    "purchase intent22": "purchase intent - social networking apps",
    "purchase intent23": "purchase intent - sports apps",
    "purchase intent24": "purchase intent - travel apps",
    "purchase intent25": "purchase intent - utilities apps",
    "purchase intent26": "purchase intent - weather apps",
    "purchase intent27": "purchase intent - arts and entertainment",
    "purchase intent28": "purchase intent - blogs/forums/social networks",
    "purchase intent29": "purchase intent - culture and fine arts",
    "purchase intent30": "purchase intent - experiences and events",
    "purchase intent31": "purchase intent - concerts",
    "purchase intent32": "purchase intent - theatre and musicals",
    "purchase intent33": "purchase intent - museums and galleries",
    "purchase intent34": "purchase intent - sporting events",
    "purchase intent35": "purchase intent - cinemas and movie events",
    "purchase intent36": "purchase intent - aviation shows",
    "purchase intent37": "purchase intent - fairs and farmer's markets",
    "purchase intent38": "purchase intent - exhibitions",
    "purchase intent39": "purchase intent - theme and amusement parks",
    "purchase intent40": "purchase intent - parks and wildlife",
    "purchase intent41": "purchase intent - auto shows",
    "purchase intent42": "purchase intent - nightclubs",
    "purchase intent43": "purchase intent - fashion events",
    "purchase intent44": "purchase intent - comedy events",
    "purchase intent45": "purchase intent - zoos and aquariums",
    "purchase intent46": "purchase intent - fan conventions",
    "purchase intent47": "purchase intent - fantasy sports",
    "purchase intent48": "purchase intent - music and video streaming services",
    "purchase intent49": "purchase intent - online entertainment",
    "purchase intent50": "purchase intent - radio and podcasts",
    "purchase intent51": "purchase intent - ticket services",
    "purchase intent52": "purchase intent - tv",
    "purchase intent53": "purchase intent - automotive ownership",
    "purchase intent54": "purchase intent - new vehicles",
    "purchase intent55": "purchase intent - commercial trucks",
    "purchase intent56": "purchase intent - sedan",
    "purchase intent57": "purchase intent - station wagon",
    "purchase intent58": "purchase intent - suv",
    "purchase intent59": "purchase intent - van",
    "purchase intent60": "purchase intent - convertible",
    "purchase intent61": "purchase intent - coupe",
    "purchase intent62": "purchase intent - crossover",
    "purchase intent63": "purchase intent - hatchback",
    "purchase intent64": "purchase intent - microcar",
    "purchase intent65": "purchase intent - minivan",
    "purchase intent66": "purchase intent - off-road vehicles",
    "purchase intent67": "purchase intent - pickup trucks",
    "purchase intent68": "purchase intent - budget cars",
    "purchase intent69": "purchase intent - classic cars",
    "purchase intent70": "purchase intent - concept cars",
    "purchase intent71": "purchase intent - driverless cars",
    "purchase intent72": "purchase intent - green vehicles",
    "purchase intent73": "purchase intent - luxury cars",
    "purchase intent74": "purchase intent - performance cars",
    "purchase intent75": "purchase intent - motorbikes",
    "purchase intent76": "purchase intent - other vehicles",
    "purchase intent77": "purchase intent - pre-owned vehicles",
    "purchase intent78": "purchase intent - commercial trucks",
    "purchase intent79": "purchase intent - sedan",
    "purchase intent80": "purchase intent - station wagon",
    "purchase intent81": "purchase intent - suv",
    "purchase intent82": "purchase intent - van",
    "purchase intent83": "purchase intent - convertible",
    "purchase intent84": "purchase intent - coupe",
    "purchase intent85": "purchase intent - crossover",
    "purchase intent86": "purchase intent - hatchback",
    "purchase intent87": "purchase intent - microcar",
    "purchase intent88": "purchase intent - minivan",
    "purchase intent89": "purchase intent - off-road vehicles",
    "purchase intent90": "purchase intent - pickup trucks",
    "purchase intent91": "purchase intent - budget cars",
    "purchase intent92": "purchase intent - classic cars",
    "purchase intent93": "purchase intent - concept cars",
    "purchase intent94": "purchase intent - driverless cars",
    "purchase intent95": "purchase intent - green vehicles",
    "purchase intent96": "purchase intent - luxury cars",
    "purchase intent97": "purchase intent - performance cars",
    "purchase intent98": "purchase intent - motorbikes",
    "purchase intent99": "purchase intent - other vehicles",
    "purchase intent100": "purchase intent - automotive products",
    "purchase intent101": "purchase intent - automotive care products",
    "purchase intent102": "purchase intent - automotive parts and accessories",
    "purchase intent103": "purchase intent - car alarms",
    "purchase intent104": "purchase intent - car amplifiers",
    "purchase intent105": "purchase intent - car seats",
    "purchase intent106": "purchase intent - car speakers",
    "purchase intent107": "purchase intent - car navigation equipment",
    "purchase intent108": "purchase intent - automotive tires",
    "purchase intent109": "purchase intent - automotive services",
    "purchase intent110": "purchase intent - auto rental",
    "purchase intent111": "purchase intent - auto towing and repair",
    "purchase intent112": "purchase intent - car wash",
    "purchase intent113": "purchase intent - beauty services",
    "purchase intent114": "purchase intent - beauty salons and tanning",
    "purchase intent115": "purchase intent - hair salons",
    "purchase intent116": "purchase intent - nail salons",
    "purchase intent117": "purchase intent - piercing and tattooing",
    "purchase intent118": "purchase intent - spas",
    "purchase intent119": "purchase intent - business and industrial",
    "purchase intent120": "purchase intent - advertising and marketing",
    "purchase intent121": "purchase intent - auctions",
    "purchase intent122": "purchase intent - conferences/events/seminars",
    "purchase intent123": "purchase intent - construction",
    "purchase intent124": "purchase intent - energy industry",
    "purchase intent125": "purchase intent - energy services",
    "purchase intent126": "purchase intent - oil, gas and consumable fuels",
    "purchase intent127": "purchase intent - electric power industry",
    "purchase intent128": "purchase intent - forestry and logging",
    "purchase intent129": "purchase intent - government",
    "purchase intent130": "purchase intent - human resources",
    "purchase intent131": "purchase intent - industrial storage",
    "purchase intent132": "purchase intent - industrials",
    "purchase intent133": "purchase intent - aerospace and defense",
    "purchase intent134": "purchase intent - construction and engineering",
    "purchase intent135": "purchase intent - industrial conglomerates",
    "purchase intent136": "purchase intent - trading companies and distributors",
    "purchase intent137": "purchase intent - transportation",
    "purchase intent138": "purchase intent - laundry and dry cleaning services",
    "purchase intent139": "purchase intent - law enforcement",
    "purchase intent140": "purchase intent - manufacturing",
    "purchase intent141": "purchase intent - material handling",
    "purchase intent142": "purchase intent - medical and biotechnology",
    "purchase intent143": "purchase intent - mining and quarrying",
    "purchase intent144": "purchase intent - photographers",
    "purchase intent145": "purchase intent - printing/fax/wifi services",
    "purchase intent146": "purchase intent - public relations and strategic communication",
    "purchase intent147": "purchase intent - retail",
    "purchase intent148": "purchase intent - cell phone stores",
    "purchase intent149": "purchase intent - music stores",
    "purchase intent150": "purchase intent - grocery stores and supermarkets",
    "purchase intent151": "purchase intent - shopping malls",
    "purchase intent152": "purchase intent - department stores",
    "purchase intent153": "purchase intent - specialty stores",
    "purchase intent154": "purchase intent - pawn shops",
    "purchase intent155": "purchase intent - factory outlet stores",
    "purchase intent156": "purchase intent - science and laboratory",
    "purchase intent157": "purchase intent - signage",
    "purchase intent158": "purchase intent - small business",
    "purchase intent159": "purchase intent - telecom services",
    "purchase intent160": "purchase intent - home internet services",
    "purchase intent161": "purchase intent - home television services",
    "purchase intent162": "purchase intent - home phone services",
    "purchase intent163": "purchase intent - mobile phone plans",
    "purchase intent164": "purchase intent - prepaid international phone services",
    "purchase intent165": "purchase intent - business telecom services",
    "purchase intent166": "purchase intent - waste disposal and recycling",
    "purchase intent167": "purchase intent - clothing and accessories",
    "purchase intent168": "purchase intent - clothing",
    "purchase intent169": "purchase intent - children's clothing",
    "purchase intent170": "purchase intent - men's clothing",
    "purchase intent171": "purchase intent - women's clothing",
    "purchase intent172": "purchase intent - wedding dresses/bridal wear/tuxedos",
    "purchase intent173": "purchase intent - maternity clothing",
    "purchase intent174": "purchase intent - underwear and lingerie",
    "purchase intent175": "purchase intent - sportswear",
    "purchase intent176": "purchase intent - clothing accessories",
    "purchase intent177": "purchase intent - costumes and accessories",
    "purchase intent178": "purchase intent - footwear",
    "purchase intent179": "purchase intent - footwear accessories",
    "purchase intent180": "purchase intent - handbags and wallets",
    "purchase intent181": "purchase intent - jewelry and watches",
    "purchase intent182": "purchase intent - sunglasses",
    "purchase intent183": "purchase intent - collectables and antiques",
    "purchase intent184": "purchase intent - antiques",
    "purchase intent185": "purchase intent - coins and paper money",
    "purchase intent186": "purchase intent - collectibles",
    "purchase intent187": "purchase intent - entertainment memorabilia",
    "purchase intent188": "purchase intent - sports memorabilia and trading cards",
    "purchase intent189": "purchase intent - stamps",
    "purchase intent190": "purchase intent - consumer electronics",
    "purchase intent191": "purchase intent - arcade equipment",
    "purchase intent192": "purchase intent - audio",
    "purchase intent193": "purchase intent - cd players",
    "purchase intent194": "purchase intent - headphones",
    "purchase intent195": "purchase intent - cameras and photo",
    "purchase intent196": "purchase intent - camera and photo accessories",
    "purchase intent197": "purchase intent - cameras",
    "purchase intent198": "purchase intent - circuit boards and components",
    "purchase intent199": "purchase intent - communications electronics",
    "purchase intent200": "purchase intent - components",
    "purchase intent201": "purchase intent - computers",
    "purchase intent202": "purchase intent - laptops",
    "purchase intent203": "purchase intent - desktops",
    "purchase intent204": "purchase intent - e-readers",
    "purchase intent205": "purchase intent - electronics accessories",
    "purchase intent206": "purchase intent - home theater systems",
    "purchase intent207": "purchase intent - marine electronics",
    "purchase intent208": "purchase intent - mobile phone plans",
    "purchase intent209": "purchase intent - mobile phones and accessories",
    "purchase intent210": "purchase intent - networking",
    "purchase intent211": "purchase intent - printers/copiers/scanners/fax",
    "purchase intent212": "purchase intent - security devices",
    "purchase intent213": "purchase intent - tablets",
    "purchase intent214": "purchase intent - televisions",
    "purchase intent215": "purchase intent - video",
    "purchase intent216": "purchase intent - blu-ray disc players",
    "purchase intent217": "purchase intent - camcorders",
    "purchase intent218": "purchase intent - video game console accessories",
    "purchase intent219": "purchase intent - video games and consoles",
    "purchase intent220": "purchase intent - consumer packaged goods",
    "purchase intent221": "purchase intent - edible",
    "purchase intent222": "purchase intent - beverages",
    "purchase intent223": "purchase intent - carbonated soft drinks",
    "purchase intent224": "purchase intent - coffee & tea",
    "purchase intent225": "purchase intent - coffee",
    "purchase intent226": "purchase intent - coffee creamer",
    "purchase intent227": "purchase intent - coffee filters",
    "purchase intent228": "purchase intent - tea - bags/loose",
    "purchase intent229": "purchase intent - tea - instant tea mixes",
    "purchase intent230": "purchase intent - tea/coffee - ready-to-drink",
    "purchase intent231": "purchase intent - drink mixes",
    "purchase intent232": "purchase intent - cocktail mixes",
    "purchase intent233": "purchase intent - drink mixes",
    "purchase intent234": "purchase intent - liquid drink enhancers",
    "purchase intent235": "purchase intent - milk flavoring cocoa mixes",
    "purchase intent236": "purchase intent - juices",
    "purchase intent237": "purchase intent - aseptic juices",
    "purchase intent238": "purchase intent - bottled juices",
    "purchase intent239": "purchase intent - canned juices",
    "purchase intent240": "purchase intent - juice/drink concentrate",
    "purchase intent241": "purchase intent - non-fruit drinks",
    "purchase intent242": "purchase intent - non-fruit drinks",
    "purchase intent243": "purchase intent - powdered milk",
    "purchase intent244": "purchase intent - sports/energy drinks",
    "purchase intent245": "purchase intent - energy drinks",
    "purchase intent246": "purchase intent - sports drinks",
    "purchase intent247": "purchase intent - water",
    "purchase intent248": "purchase intent - bottled water",
    "purchase intent249": "purchase intent - frozen",
    "purchase intent250": "purchase intent - frozen baked goods",
    "purchase intent251": "purchase intent - bread/dough",
    "purchase intent252": "purchase intent - cookies",
    "purchase intent253": "purchase intent - pies",
    "purchase intent254": "purchase intent - frozen beverages",
    "purchase intent255": "purchase intent - coffee creamer",
    "purchase intent256": "purchase intent - juices",
    "purchase intent257": "purchase intent - frozen desserts",
    "purchase intent258": "purchase intent - desserts/toppings",
    "purchase intent259": "purchase intent - ice cream/sherbet",
    "purchase intent260": "purchase intent - novelties",
    "purchase intent261": "purchase intent - frozen fruits & vegetables",
    "purchase intent262": "purchase intent - corn on the cob",
    "purchase intent263": "purchase intent - fruit",
    "purchase intent264": "purchase intent - plain vegetables",
    "purchase intent265": "purchase intent - potatoes/onions",
    "purchase intent266": "purchase intent - prepared vegetables",
    "purchase intent267": "purchase intent - frozen meals",
    "purchase intent268": "purchase intent - breakfast food",
    "purchase intent269": "purchase intent - dinners/entrees",
    "purchase intent270": "purchase intent - pasta",
    "purchase intent271": "purchase intent - pizza",
    "purchase intent272": "purchase intent - soups/sides/other",
    "purchase intent273": "purchase intent - frozen meat/poultry/seafood",
    "purchase intent274": "purchase intent - meat",
    "purchase intent275": "purchase intent - poultry",
    "purchase intent276": "purchase intent - processed poultry",
    "purchase intent277": "purchase intent - seafood",
    "purchase intent278": "purchase intent - frozen snacks",
    "purchase intent279": "purchase intent - appetizers/snack rolls",
    "purchase intent280": "purchase intent - other snacks",
    "purchase intent281": "purchase intent - other frozen",
    "purchase intent282": "purchase intent - baby food",
    "purchase intent283": "purchase intent - other food",
    "purchase intent284": "purchase intent - general food",
    "purchase intent285": "purchase intent - baby food",
    "purchase intent286": "purchase intent - baby food",
    "purchase intent287": "purchase intent - baby formula/electrolytes",
    "purchase intent288": "purchase intent - bakery",
    "purchase intent289": "purchase intent - bagels/bialys",
    "purchase intent290": "purchase intent - bakery snacks",
    "purchase intent291": "purchase intent - english muffins",
    "purchase intent292": "purchase intent - fresh bread & rolls",
    "purchase intent293": "purchase intent - pastry/doughnuts",
    "purchase intent294": "purchase intent - pies & cakes",
    "purchase intent295": "purchase intent - baking",
    "purchase intent296": "purchase intent - baking cups/paper",
    "purchase intent297": "purchase intent - baking mixes",
    "purchase intent298": "purchase intent - baking needs",
    "purchase intent299": "purchase intent - baking nuts",
    "purchase intent300": "purchase intent - baking syrum/molasses",
    "purchase intent301": "purchase intent - dessert toppings",
    "purchase intent302": "purchase intent - egg substitute",
    "purchase intent303": "purchase intent - evaporated/condensed milk",
    "purchase intent304": "purchase intent - flour/meal",
    "purchase intent305": "purchase intent - frosting",
    "purchase intent306": "purchase intent - frt & veg preservative/pectin",
    "purchase intent307": "purchase intent - gelatin/pudding prd and mixes",
    "purchase intent308": "purchase intent - glazed fruits",
    "purchase intent309": "purchase intent - ice cream cones/mixes",
    "purchase intent310": "purchase intent - marshmallows",
    "purchase intent311": "purchase intent - shortening & oil",
    "purchase intent312": "purchase intent - spices/seasonings",
    "purchase intent313": "purchase intent - sugar",
    "purchase intent314": "purchase intent - sugar substitutes",
    "purchase intent315": "purchase intent - breakfast",
    "purchase intent316": "purchase intent - cold cereal",
    "purchase intent317": "purchase intent - hot cereal",
    "purchase intent318": "purchase intent - other breakfast foods",
    "purchase intent319": "purchase intent - pancake mixes",
    "purchase intent320": "purchase intent - syrup",
    "purchase intent321": "purchase intent - toaster pastries/tarts",
    "purchase intent322": "purchase intent - candy",
    "purchase intent323": "purchase intent - breath fresheners",
    "purchase intent324": "purchase intent - chocolate candy",
    "purchase intent325": "purchase intent - gum",
    "purchase intent326": "purchase intent - non-chocolate candy",
    "purchase intent327": "purchase intent - condiments & sauces",
    "purchase intent328": "purchase intent - barbeque sauce",
    "purchase intent329": "purchase intent - gravy/sauce mixes",
    "purchase intent330": "purchase intent - jellies/jam/honey",
    "purchase intent331": "purchase intent - mayonnaise",
    "purchase intent332": "purchase intent - mustard & ketchup",
    "purchase intent333": "purchase intent - nut butter",
    "purchase intent334": "purchase intent - other sauces",
    "purchase intent335": "purchase intent - pickles/relish/olives",
    "purchase intent336": "purchase intent - salad  dressing",
    "purchase intent337": "purchase intent - salad toppings and croutons",
    "purchase intent338": "purchase intent - steak/worcestershire  sauce",
    "purchase intent339": "purchase intent - vinegar",
    "purchase intent340": "purchase intent - cookies & crackers",
    "purchase intent341": "purchase intent - cookies",
    "purchase intent342": "purchase intent - crackers",
    "purchase intent343": "purchase intent - ethnic",
    "purchase intent344": "purchase intent - asian food",
    "purchase intent345": "purchase intent - matzoh food",
    "purchase intent346": "purchase intent - mexican foods",
    "purchase intent347": "purchase intent - mexican sauce",
    "purchase intent348": "purchase intent - meals",
    "purchase intent349": "purchase intent - baked beans/canned bread",
    "purchase intent350": "purchase intent - breadcrumbs/batters",
    "purchase intent351": "purchase intent - dinners",
    "purchase intent352": "purchase intent - dry packaged dinner mixes",
    "purchase intent353": "purchase intent - grated cheese",
    "purchase intent354": "purchase intent - instant potatoes",
    "purchase intent355": "purchase intent - meat",
    "purchase intent356": "purchase intent - pasta",
    "purchase intent357": "purchase intent - pizza products",
    "purchase intent358": "purchase intent - rice",
    "purchase intent359": "purchase intent - seafood",
    "purchase intent360": "purchase intent - soup",
    "purchase intent361": "purchase intent - spaghetti/italian sauce",
    "purchase intent362": "purchase intent - stuffing mixes",
    "purchase intent363": "purchase intent - snacks",
    "purchase intent364": "purchase intent - dip/dip mixes",
    "purchase intent365": "purchase intent - dried meat snacks",
    "purchase intent366": "purchase intent - dry fruit snacks",
    "purchase intent367": "purchase intent - other snacks",
    "purchase intent368": "purchase intent - popcorn/popcorn oil",
    "purchase intent369": "purchase intent - rice/popcorn cakes",
    "purchase intent370": "purchase intent - salty snacks",
    "purchase intent371": "purchase intent - snack bars/granola bars",
    "purchase intent372": "purchase intent - snack nuts/seeds/corn nuts",
    "purchase intent373": "purchase intent - fruit",
    "purchase intent374": "purchase intent - canned/bottled fruit",
    "purchase intent375": "purchase intent - dried fruit",
    "purchase intent376": "purchase intent - vegetables",
    "purchase intent377": "purchase intent - dry beans/vegetables",
    "purchase intent378": "purchase intent - tomato products",
    "purchase intent379": "purchase intent - vegetables",
    "purchase intent380": "purchase intent - refrigerated",
    "purchase intent381": "purchase intent - dairy",
    "purchase intent382": "purchase intent - butter/butter blends",
    "purchase intent383": "purchase intent - cottage cheese",
    "purchase intent384": "purchase intent - cream cheese/cr  spread",
    "purchase intent385": "purchase intent - creams/creamers",
    "purchase intent386": "purchase intent - fresh eggs",
    "purchase intent387": "purchase intent - margarine/spreads",
    "purchase intent388": "purchase intent - milk",
    "purchase intent389": "purchase intent - natural cheese",
    "purchase intent390": "purchase intent - processed cheese",
    "purchase intent391": "purchase intent - sour cream",
    "purchase intent392": "purchase intent - whipped toppings",
    "purchase intent393": "purchase intent - yogurt",
    "purchase intent394": "purchase intent - other refrigerated",
    "purchase intent395": "purchase intent - lard",
    "purchase intent396": "purchase intent - tortilla/eggroll/wonton wrap",
    "purchase intent397": "purchase intent - refrigerated baked goods",
    "purchase intent398": "purchase intent - baked goods",
    "purchase intent399": "purchase intent - refrigerated beverages",
    "purchase intent400": "purchase intent - juices/drinks",
    "purchase intent401": "purchase intent - tea/coffee",
    "purchase intent402": "purchase intent - refrigerated condiments",
    "purchase intent403": "purchase intent - dips",
    "purchase intent404": "purchase intent - other condiments",
    "purchase intent405": "purchase intent - pickles/relish",
    "purchase intent406": "purchase intent - salad dressing",
    "purchase intent407": "purchase intent - spreads",
    "purchase intent408": "purchase intent - refrigerated desserts",
    "purchase intent409": "purchase intent - cheesecakes",
    "purchase intent410": "purchase intent - desserts",
    "purchase intent411": "purchase intent - refrigerated dough",
    "purchase intent412": "purchase intent - dough/biscuit dough",
    "purchase intent413": "purchase intent - pizza",
    "purchase intent414": "purchase intent - refrigerated meals",
    "purchase intent415": "purchase intent - entrees",
    "purchase intent416": "purchase intent - lunches",
    "purchase intent417": "purchase intent - meat pies",
    "purchase intent418": "purchase intent - pasta",
    "purchase intent419": "purchase intent - side dishes",
    "purchase intent420": "purchase intent - breakfast meats",
    "purchase intent421": "purchase intent - dinner sausage",
    "purchase intent422": "purchase intent - frankfurters",
    "purchase intent423": "purchase intent - ham",
    "purchase intent424": "purchase intent - luncheon meats",
    "purchase intent425": "purchase intent - meat",
    "purchase intent426": "purchase intent - seafood",
    "purchase intent427": "purchase intent - non-edible",
    "purchase intent428": "purchase intent - beauty",
    "purchase intent429": "purchase intent - cosmetics",
    "purchase intent430": "purchase intent - storage",
    "purchase intent431": "purchase intent - eye",
    "purchase intent432": "purchase intent - facial",
    "purchase intent433": "purchase intent - lip",
    "purchase intent434": "purchase intent - cosmetics-nail",
    "purchase intent435": "purchase intent - cosmetics accessories",
    "purchase intent436": "purchase intent - fragrance",
    "purchase intent437": "purchase intent - fragrances - women's",
    "purchase intent438": "purchase intent - shaving lotion/men's fragrance",
    "purchase intent439": "purchase intent - grooming supplies",
    "purchase intent440": "purchase intent - cotton balls/swabs",
    "purchase intent441": "purchase intent - electric shaver groomer",
    "purchase intent442": "purchase intent - hair appliances",
    "purchase intent443": "purchase intent - other grooming supplies",
    "purchase intent444": "purchase intent - hair care",
    "purchase intent445": "purchase intent - hair accessories",
    "purchase intent446": "purchase intent - hair coloring",
    "purchase intent447": "purchase intent - hair conditioner",
    "purchase intent448": "purchase intent - hair growth products",
    "purchase intent449": "purchase intent - hair spray/spritz",
    "purchase intent450": "purchase intent - hair styling gel/mousse",
    "purchase intent451": "purchase intent - home permanent/relaxer kits",
    "purchase intent452": "purchase intent - shampoo",
    "purchase intent453": "purchase intent - personal cleansing",
    "purchase intent454": "purchase intent - bath products",
    "purchase intent455": "purchase intent - bath/body scrubbers/massagers",
    "purchase intent456": "purchase intent - deodorant",
    "purchase intent457": "purchase intent - moist towelettes",
    "purchase intent458": "purchase intent - soap",
    "purchase intent459": "purchase intent - shaving",
    "purchase intent460": "purchase intent - blades",
    "purchase intent461": "purchase intent - razors",
    "purchase intent462": "purchase intent - shaving cream",
    "purchase intent463": "purchase intent - skin care",
    "purchase intent464": "purchase intent - hand & body lotion",
    "purchase intent465": "purchase intent - skin care",
    "purchase intent466": "purchase intent - suntan products",
    "purchase intent467": "purchase intent - general merchandise",
    "purchase intent468": "purchase intent - automotive",
    "purchase intent469": "purchase intent - automobile fluids/antifreeze",
    "purchase intent470": "purchase intent - automobile waxes/polishes",
    "purchase intent471": "purchase intent - motor oil",
    "purchase intent472": "purchase intent - barbeque",
    "purchase intent473": "purchase intent - charcoal",
    "purchase intent474": "purchase intent - charcoal lighter fluids",
    "purchase intent475": "purchase intent - disposable tableware",
    "purchase intent476": "purchase intent - cups & plates",
    "purchase intent477": "purchase intent - disposable tableware",
    "purchase intent478": "purchase intent - electronics/photography",
    "purchase intent479": "purchase intent - batteries",
    "purchase intent480": "purchase intent - blank audio/video media",
    "purchase intent481": "purchase intent - photography supplies",
    "purchase intent482": "purchase intent - foils, wraps, & bags",
    "purchase intent483": "purchase intent - foil pans",
    "purchase intent484": "purchase intent - foils & wraps",
    "purchase intent485": "purchase intent - food & trash bags",
    "purchase intent486": "purchase intent - hosiery",
    "purchase intent487": "purchase intent - pantyhose/nylons",
    "purchase intent488": "purchase intent - socks",
    "purchase intent489": "purchase intent - tights",
    "purchase intent490": "purchase intent - household/plastics/storage",
    "purchase intent491": "purchase intent - bottles",
    "purchase intent492": "purchase intent - drinkware",
    "purchase intent493": "purchase intent - household plastics",
    "purchase intent494": "purchase intent - kitchen storage",
    "purchase intent495": "purchase intent - soap dishes",
    "purchase intent496": "purchase intent - miscellaneous general merch",
    "purchase intent497": "purchase intent - candles",
    "purchase intent498": "purchase intent - cloth dye",
    "purchase intent499": "purchase intent - culinary",
    "purchase intent500": "purchase intent - firelog/firestarter/firewood",
    "purchase intent501": "purchase intent - flashlights",
    "purchase intent502": "purchase intent - frozen & dry ice",
    "purchase intent503": "purchase intent - gloves",
    "purchase intent504": "purchase intent - household lubricants",
    "purchase intent505": "purchase intent - ice substitute",
    "purchase intent506": "purchase intent - light bulbs",
    "purchase intent507": "purchase intent - lighters",
    "purchase intent508": "purchase intent - matches",
    "purchase intent509": "purchase intent - outdoor/lawn fertilizer/weed killer",
    "purchase intent510": "purchase intent - playing cards",
    "purchase intent511": "purchase intent - pool/spa chemicals",
    "purchase intent512": "purchase intent - shoe polish & accessories",
    "purchase intent513": "purchase intent - vacuum bags/belts",
    "purchase intent514": "purchase intent - office/school supplies",
    "purchase intent515": "purchase intent - children's art supplies",
    "purchase intent516": "purchase intent - computer disks frmtd/unfrmtd",
    "purchase intent517": "purchase intent - office products",
    "purchase intent518": "purchase intent - writing instruments",
    "purchase intent519": "purchase intent - paper products",
    "purchase intent520": "purchase intent - facial tissue",
    "purchase intent521": "purchase intent - paper napkins",
    "purchase intent522": "purchase intent - paper towels",
    "purchase intent523": "purchase intent - toilet tissue",
    "purchase intent524": "purchase intent - pest control",
    "purchase intent525": "purchase intent - outdoor insect/rodent control chem",
    "purchase intent526": "purchase intent - pest control",
    "purchase intent527": "purchase intent - pet care",
    "purchase intent528": "purchase intent - cat/dog litter",
    "purchase intent529": "purchase intent - pet food",
    "purchase intent530": "purchase intent - pet supplies",
    "purchase intent531": "purchase intent - pet treats",
    "purchase intent532": "purchase intent - water treatment",
    "purchase intent533": "purchase intent - water filter/devices",
    "purchase intent534": "purchase intent - water softeners/treatment",
    "purchase intent535": "purchase intent - household appliances",
    "purchase intent536": "purchase intent - air conditioners",
    "purchase intent537": "purchase intent - air purifiers",
    "purchase intent538": "purchase intent - blenders",
    "purchase intent539": "purchase intent - breadmakers",
    "purchase intent540": "purchase intent - coffee grinders",
    "purchase intent541": "purchase intent - coffee makers",
    "purchase intent542": "purchase intent - deep fryers",
    "purchase intent543": "purchase intent - dehumidifiers",
    "purchase intent544": "purchase intent - dishwashers",
    "purchase intent545": "purchase intent - espresso machines",
    "purchase intent546": "purchase intent - fans",
    "purchase intent547": "purchase intent - food processors",
    "purchase intent548": "purchase intent - freezers",
    "purchase intent549": "purchase intent - heaters",
    "purchase intent550": "purchase intent - humidifiers",
    "purchase intent551": "purchase intent - ice cream makers",
    "purchase intent552": "purchase intent - juicers",
    "purchase intent553": "purchase intent - microwave ovens",
    "purchase intent554": "purchase intent - mixers",
    "purchase intent555": "purchase intent - ovens",
    "purchase intent556": "purchase intent - ranges",
    "purchase intent557": "purchase intent - refrigerators",
    "purchase intent558": "purchase intent - sandwich makers",
    "purchase intent559": "purchase intent - tea kettles",
    "purchase intent560": "purchase intent - toasters",
    "purchase intent561": "purchase intent - vacuums",
    "purchase intent562": "purchase intent - washers and dryers",
    "purchase intent563": "purchase intent - home care",
    "purchase intent564": "purchase intent - air fresheners",
    "purchase intent565": "purchase intent - household cleaning",
    "purchase intent566": "purchase intent - cleaning tools/mops/brooms",
    "purchase intent567": "purchase intent - dish detergent",
    "purchase intent568": "purchase intent - floor cleaners/wax removers",
    "purchase intent569": "purchase intent - furniture polish",
    "purchase intent570": "purchase intent - household cleaner",
    "purchase intent571": "purchase intent - household cleaner cloths",
    "purchase intent572": "purchase intent - multi task sheets",
    "purchase intent573": "purchase intent - rug/upholstery/fabric treatment",
    "purchase intent574": "purchase intent - sponges & scouring pads",
    "purchase intent575": "purchase intent - laundry",
    "purchase intent576": "purchase intent - bleach",
    "purchase intent577": "purchase intent - fabric softener",
    "purchase intent578": "purchase intent - laundry care",
    "purchase intent579": "purchase intent - laundry detergent",
    "purchase intent580": "purchase intent - home and garden products",
    "purchase intent581": "purchase intent - bathroom accessories",
    "purchase intent582": "purchase intent - home decor",
    "purchase intent583": "purchase intent - bedroom furniture and accessories",
    "purchase intent584": "purchase intent - fireplaces",
    "purchase intent585": "purchase intent - kitchen and dining products",
    "purchase intent586": "purchase intent - lawn and garden products",
    "purchase intent587": "purchase intent - lighting",
    "purchase intent588": "purchase intent - linens and bedding",
    "purchase intent589": "purchase intent - plants",
    "purchase intent590": "purchase intent - housewares",
    "purchase intent591": "purchase intent - carpets and rugs",
    "purchase intent592": "purchase intent - religious items",
    "purchase intent593": "purchase intent - back to school supplies",
    "purchase intent594": "purchase intent - baby and toddler products",
    "purchase intent595": "purchase intent - baby bath and potty products",
    "purchase intent596": "purchase intent - baby gift sets",
    "purchase intent597": "purchase intent - baby toys and activity equipment",
    "purchase intent598": "purchase intent - diapers",
    "purchase intent599": "purchase intent - baby safety products",
    "purchase intent600": "purchase intent - nursing and feeding products",
    "purchase intent601": "purchase intent - baby bouncers and rockers",
    "purchase intent602": "purchase intent - baby carriers",
    "purchase intent603": "purchase intent - high chairs and boosters",
    "purchase intent604": "purchase intent - strollers and joggers",
    "purchase intent605": "purchase intent - media",
    "purchase intent606": "purchase intent - magazines and newspapers",
    "purchase intent607": "purchase intent - dvds",
    "purchase intent608": "purchase intent - books and audio books",
    "purchase intent609": "purchase intent - cds and vinyl records",
    "purchase intent610": "purchase intent - toys and games",
    "purchase intent611": "purchase intent - games",
    "purchase intent612": "purchase intent - outdoor play equipment",
    "purchase intent613": "purchase intent - puzzles",
    "purchase intent614": "purchase intent - toys",
    "purchase intent615": "purchase intent - luggage and bags",
    "purchase intent616": "purchase intent - education and careers",
    "purchase intent617": "purchase intent - adult education",
    "purchase intent618": "purchase intent - career improvement and advice",
    "purchase intent619": "purchase intent - colleges and universities",
    "purchase intent620": "purchase intent - employment agencies",
    "purchase intent621": "purchase intent - language learning",
    "purchase intent622": "purchase intent - online education",
    "purchase intent623": "purchase intent - study skills",
    "purchase intent624": "purchase intent - teaching resources",
    "purchase intent625": "purchase intent - family and parenting",
    "purchase intent626": "purchase intent - childcare",
    "purchase intent627": "purchase intent - day care centers",
    "purchase intent628": "purchase intent - nanny services",
    "purchase intent629": "purchase intent - genealogy and family trees",
    "purchase intent630": "purchase intent - kids activities",
    "purchase intent631": "purchase intent - finance and insurance",
    "purchase intent632": "purchase intent - accountants",
    "purchase intent633": "purchase intent - banking",
    "purchase intent634": "purchase intent - bookkeepers",
    "purchase intent635": "purchase intent - credit and debt repair/credit reporting",
    "purchase intent636": "purchase intent - credit cards",
    "purchase intent637": "purchase intent - insurance",
    "purchase intent638": "purchase intent - auto insurance",
    "purchase intent639": "purchase intent - home insurance",
    "purchase intent640": "purchase intent - life insurance",
    "purchase intent641": "purchase intent - medical insurance",
    "purchase intent642": "purchase intent - mortgage lenders and brokers",
    "purchase intent643": "purchase intent - payday and emergency loans",
    "purchase intent644": "purchase intent - retirement planning",
    "purchase intent645": "purchase intent - stocks and investments",
    "purchase intent646": "purchase intent - student financial aid",
    "purchase intent647": "purchase intent - tax preparation services",
    "purchase intent648": "purchase intent - food and beverage services",
    "purchase intent649": "purchase intent - bakeries",
    "purchase intent650": "purchase intent - bars",
    "purchase intent651": "purchase intent - catering",
    "purchase intent652": "purchase intent - fast food",
    "purchase intent653": "purchase intent - food delivery services",
    "purchase intent654": "purchase intent - restaurants",
    "purchase intent655": "purchase intent - furniture",
    "purchase intent656": "purchase intent - baby and toddler furniture",
    "purchase intent657": "purchase intent - bbq/grills/outdoor dining",
    "purchase intent658": "purchase intent - beds and accessories",
    "purchase intent659": "purchase intent - benches",
    "purchase intent660": "purchase intent - cabinets and storage",
    "purchase intent661": "purchase intent - chairs",
    "purchase intent662": "purchase intent - entertainment centers and tv stands",
    "purchase intent663": "purchase intent - furniture sets",
    "purchase intent664": "purchase intent - outdoor furniture",
    "purchase intent665": "purchase intent - shelving",
    "purchase intent666": "purchase intent - sofas",
    "purchase intent667": "purchase intent - tables",
    "purchase intent668": "purchase intent - gifts and holiday items",
    "purchase intent669": "purchase intent - flowers",
    "purchase intent670": "purchase intent - gift baskets",
    "purchase intent671": "purchase intent - gift cards and coupons",
    "purchase intent672": "purchase intent - gift certificates",
    "purchase intent673": "purchase intent - greeting cards",
    "purchase intent674": "purchase intent - holiday and ethnic items",
    "purchase intent675": "purchase intent - party goods",
    "purchase intent676": "purchase intent - hardware supplies",
    "purchase intent677": "purchase intent - agriculture and farming equipment",
    "purchase intent678": "purchase intent - building materials",
    "purchase intent679": "purchase intent - building products",
    "purchase intent680": "purchase intent - building supplies",
    "purchase intent681": "purchase intent - carpentry supplies",
    "purchase intent682": "purchase intent - electrical equipment",
    "purchase intent683": "purchase intent - fencing and barriers",
    "purchase intent684": "purchase intent - fuel containers and tanks",
    "purchase intent685": "purchase intent - hardware accessories",
    "purchase intent686": "purchase intent - hardware pumps",
    "purchase intent687": "purchase intent - heating/ventilation/air conditioning",
    "purchase intent688": "purchase intent - locks and keys",
    "purchase intent689": "purchase intent - machinery",
    "purchase intent690": "purchase intent - plumbing supplies",
    "purchase intent691": "purchase intent - power and electrical supplies",
    "purchase intent692": "purchase intent - small engines",
    "purchase intent693": "purchase intent - storage tanks",
    "purchase intent694": "purchase intent - tools",
    "purchase intent695": "purchase intent - work safety protective gear",
    "purchase intent696": "purchase intent - health and medical services",
    "purchase intent697": "purchase intent - alternative and natural medicine",
    "purchase intent698": "purchase intent - chiropractors",
    "purchase intent699": "purchase intent - clinical research",
    "purchase intent700": "purchase intent - cosmetic medical services",
    "purchase intent701": "purchase intent - dental care",
    "purchase intent702": "purchase intent - drugstores and pharmacies",
    "purchase intent703": "purchase intent - elder care",
    "purchase intent704": "purchase intent - hair loss treatments",
    "purchase intent705": "purchase intent - health care and physicians",
    "purchase intent706": "purchase intent - health services",
    "purchase intent707": "purchase intent - hospitals",
    "purchase intent708": "purchase intent - massage therapists",
    "purchase intent709": "purchase intent - physical therapists",
    "purchase intent710": "purchase intent - skin care treatments",
    "purchase intent711": "purchase intent - smoking cessation",
    "purchase intent712": "purchase intent - vaccines",
    "purchase intent713": "purchase intent - vision care",
    "purchase intent714": "purchase intent - hobbies and interests",
    "purchase intent715": "purchase intent - arts and crafts",
    "purchase intent716": "purchase intent - musical instruments and accessories",
    "purchase intent717": "purchase intent - psychics and astrology",
    "purchase intent718": "purchase intent - workshops and classes",
    "purchase intent719": "purchase intent - home and garden services",
    "purchase intent720": "purchase intent - appliance repair",
    "purchase intent721": "purchase intent - business and home security services",
    "purchase intent722": "purchase intent - carpeting and flooring services",
    "purchase intent723": "purchase intent - emergency preparedness",
    "purchase intent724": "purchase intent - flood, fire and gas safety",
    "purchase intent725": "purchase intent - gas and electric services",
    "purchase intent726": "purchase intent - home improvement and repair",
    "purchase intent727": "purchase intent - housekeeping services",
    "purchase intent728": "purchase intent - landscaping services",
    "purchase intent729": "purchase intent - lawn and garden services",
    "purchase intent730": "purchase intent - pest exterminators",
    "purchase intent731": "purchase intent - plumbers",
    "purchase intent732": "purchase intent - pool and spa installation and maintenance",
    "purchase intent733": "purchase intent - remodeling and construction",
    "purchase intent734": "purchase intent - water services",
    "purchase intent735": "purchase intent - window installation and treatments",
    "purchase intent736": "purchase intent - legal services",
    "purchase intent737": "purchase intent - attorneys",
    "purchase intent738": "purchase intent - bail bonds",
    "purchase intent739": "purchase intent - life events",
    "purchase intent740": "purchase intent - anniversary",
    "purchase intent741": "purchase intent - baby showers",
    "purchase intent742": "purchase intent - bachelor and bachelorette parties",
    "purchase intent743": "purchase intent - birthdays",
    "purchase intent744": "purchase intent - births",
    "purchase intent745": "purchase intent - funeral supplies and services",
    "purchase intent746": "purchase intent - graduations",
    "purchase intent747": "purchase intent - proms",
    "purchase intent748": "purchase intent - wedding services and supplies",
    "purchase intent749": "purchase intent - logistics and delivery",
    "purchase intent750": "purchase intent - shipping services",
    "purchase intent751": "purchase intent - storage facilities",
    "purchase intent752": "purchase intent - non-profits",
    "purchase intent753": "purchase intent - charities and donations",
    "purchase intent754": "purchase intent - civic organizations",
    "purchase intent755": "purchase intent - federations and professional associations",
    "purchase intent756": "purchase intent - military organizations",
    "purchase intent757": "purchase intent - ngos",
    "purchase intent758": "purchase intent - psas",
    "purchase intent759": "purchase intent - office equipment and supplies",
    "purchase intent760": "purchase intent - office furniture",
    "purchase intent761": "purchase intent - stationery",
    "purchase intent762": "purchase intent - pet services",
    "purchase intent763": "purchase intent - pet breeders",
    "purchase intent764": "purchase intent - pet grooming",
    "purchase intent765": "purchase intent - pet sitting",
    "purchase intent766": "purchase intent - pet stores",
    "purchase intent767": "purchase intent - veterinary services",
    "purchase intent768": "purchase intent - pharmaceuticals, conditions, and symptoms",
    "purchase intent769": "purchase intent - real estate",
    "purchase intent770": "purchase intent - commercial real estate",
    "purchase intent771": "purchase intent - real estate rentals",
    "purchase intent772": "purchase intent - real estate sales",
    "purchase intent773": "purchase intent - real estate services for owners",
    "purchase intent774": "purchase intent - residential real estate",
    "purchase intent775": "purchase intent - recreation and fitness activities",
    "purchase intent776": "purchase intent - dance studios",
    "purchase intent777": "purchase intent - gyms and health clubs",
    "purchase intent778": "purchase intent - participant sports leagues",
    "purchase intent779": "purchase intent - personal trainers",
    "purchase intent780": "purchase intent - self defense and martial arts classes",
    "purchase intent781": "purchase intent - swimming facilities",
    "purchase intent782": "purchase intent - yoga studios",
    "purchase intent783": "purchase intent - software",
    "purchase intent784": "purchase intent - computer software",
    "purchase intent785": "purchase intent - 3-d graphics",
    "purchase intent786": "purchase intent - photo editing software",
    "purchase intent787": "purchase intent - shareware and freeware",
    "purchase intent788": "purchase intent - video software",
    "purchase intent789": "purchase intent - web conferencing",
    "purchase intent790": "purchase intent - antivirus software",
    "purchase intent791": "purchase intent - browsers",
    "purchase intent792": "purchase intent - computer animation",
    "purchase intent793": "purchase intent - databases",
    "purchase intent794": "purchase intent - desktop publishing",
    "purchase intent795": "purchase intent - digital audio",
    "purchase intent796": "purchase intent - graphics software",
    "purchase intent797": "purchase intent - operating systems",
    "purchase intent798": "purchase intent - productivity software",
    "purchase intent799": "purchase intent - messaging software",
    "purchase intent800": "purchase intent - gaming software",
    "purchase intent801": "purchase intent - digital goods and currency",
    "purchase intent802": "purchase intent - sporting goods",
    "purchase intent803": "purchase intent - athletics equipment",
    "purchase intent804": "purchase intent - baseball and softball equipment",
    "purchase intent805": "purchase intent - basketball equipment",
    "purchase intent806": "purchase intent - boxing and martial arts equipment",
    "purchase intent807": "purchase intent - figure skating and hockey equipment",
    "purchase intent808": "purchase intent - football equipment",
    "purchase intent809": "purchase intent - general purpose athletic equipment",
    "purchase intent810": "purchase intent - gymnastics equipment",
    "purchase intent811": "purchase intent - soccer equipment",
    "purchase intent812": "purchase intent - tennis equipment",
    "purchase intent813": "purchase intent - track and field equipment",
    "purchase intent814": "purchase intent - volleyball equipment",
    "purchase intent815": "purchase intent - water polo equipment",
    "purchase intent816": "purchase intent - wrestling equipment",
    "purchase intent817": "purchase intent - exercise and fitness equipment",
    "purchase intent818": "purchase intent - indoor games equipment",
    "purchase intent819": "purchase intent - outdoor recreation equipment",
    "purchase intent820": "purchase intent - boating and water sports equipment",
    "purchase intent821": "purchase intent - camping and hiking equipment",
    "purchase intent822": "purchase intent - climbing equipment",
    "purchase intent823": "purchase intent - bicycles and cycling equipment",
    "purchase intent824": "purchase intent - equestrian equipment",
    "purchase intent825": "purchase intent - fishing equipment",
    "purchase intent826": "purchase intent - golf equipment",
    "purchase intent827": "purchase intent - hang gliding and skydiving equipment",
    "purchase intent828": "purchase intent - hunting and shooting equipment",
    "purchase intent829": "purchase intent - inline and roller skating equipment",
    "purchase intent830": "purchase intent - outdoor games equipment",
    "purchase intent831": "purchase intent - skateboards and accessories",
    "purchase intent832": "purchase intent - winter sports equipment",
    "purchase intent833": "purchase intent - travel and tourism",
    "purchase intent834": "purchase intent - adventure travel",
    "purchase intent835": "purchase intent - air travel",
    "purchase intent836": "purchase intent - auto rental",
    "purchase intent837": "purchase intent - beach travel",
    "purchase intent838": "purchase intent - bed and breakfasts",
    "purchase intent839": "purchase intent - budget travel",
    "purchase intent840": "purchase intent - business travel",
    "purchase intent841": "purchase intent - taxi services",
    "purchase intent842": "purchase intent - ride-sharing services",
    "purchase intent843": "purchase intent - camping",
    "purchase intent844": "purchase intent - coach travel",
    "purchase intent845": "purchase intent - cruise travel",
    "purchase intent846": "purchase intent - day trips",
    "purchase intent847": "purchase intent - family travel",
    "purchase intent848": "purchase intent - ferry travel",
    "purchase intent849": "purchase intent - honeymoons and getaways",
    "purchase intent850": "purchase intent - hotels and resorts",
    "purchase intent851": "purchase intent - motels",
    "purchase intent852": "purchase intent - passenger transportation",
    "purchase intent853": "purchase intent - rail travel",
    "purchase intent854": "purchase intent - road trips",
    "purchase intent855": "purchase intent - sightseeing tours and activities",
    "purchase intent856": "purchase intent - spas",
    "purchase intent857": "purchase intent - timeshares",
    "purchase intent858": "purchase intent - travel agents and online travel services",
    "purchase intent859": "purchase intent - travel insurance",
    "purchase intent860": "purchase intent - web services",
    "purchase intent861": "purchase intent - domain services",
    "purchase intent862": "purchase intent - internet providers",
    "purchase intent863": "purchase intent - web hosting and cloud computing",
};

export default AUDIENCE_MAP;
