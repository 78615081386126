import { v4 as uuidv4 } from "uuid";

import onPostLcp from "../utils/onPostLcp";
import getContext from "../context";
import fullBootstrap from "../transport/fullBootstrap.js";

import AUDIENCE_MAP from "./audience_map.js";

const LOCAL_STORAGE_KEY = "rm_iab_id";

function getUuid() {
    return uuidv4();
}

function parseAudience(rawAudience) {
    if (Array.isArray(rawAudience)) {
        return rawAudience;
    }

    const aud = rawAudience.split(",");
    return aud;
}

function findRealName(encodedName) {
    if (encodedName in AUDIENCE_MAP) {
        return AUDIENCE_MAP[encodedName];
    }
}

function audienceNamReducer(acum, encodedName) {
    if (!encodedName) {
        return acum;
    }

    const realName = findRealName(encodedName.trim().toLowerCase());
    if (realName) {
        acum.push(realName);
    }

    return acum;
}

function findRealAudienceNames(encodedAudienceList) {
    return encodedAudienceList.reduce(audienceNamReducer, []);
}

function getUserId() {
    let stored = localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!stored) {
        stored = getUuid();
        localStorage.setItem(LOCAL_STORAGE_KEY, stored);
    }

    return stored;
}

function makePayload(tags) {
    const uid = getUserId();
    return { tags, uid };
}

function processAudience(rawAudience) {
    const audienceList = parseAudience(rawAudience);
    const nameAudiences = findRealAudienceNames(audienceList);
    if (nameAudiences.length === 0) {
        return;
    }

    const payload = makePayload(nameAudiences);

    navigator.sendBeacon("/r/tracking/iab", JSON.stringify(payload));
}

function processPost(post) {
    if ("roar_specific_data" in post && "custom_aud" in post.roar_specific_data) {
        return processAudience(post.roar_specific_data.custom_aud);
    }
}

function handleBoostrapResponse(bootstrap) {
    if ("post" in bootstrap) {
        return processPost(bootstrap.post);
    }
}

function prepareIabTracking() {
    const context = getContext();
    fullBootstrap(context).then(handleBoostrapResponse).catch(console.error);
}

onPostLcp(prepareIabTracking);
